<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :retain-focus="false"
    :value="dialog"
    :max-width="1200"
    @input="closeModal"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeModal">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <v-form ref="search" lazy-validation v-model="validSearch">
          <v-row dense class="mt-2">
            <v-col cols="12" xl="4" lg="4" md="4" sm="6" v-if="employeeName">
              <v-text-field
                label="Nome"
                dense
                outlined
                disabled
                :value="employeeName"
              />
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <BaseContractsMovementsDependents
                label="Contratos"
                required
                :rules="[requiredLength]"
                :employeeId="employeeId"
                :dependentId="dependentId"
                v-model="contracts"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <BaseButton
                height="40"
                color="primary"
                title="Filtrar"
                icon="fa-solid fa-magnifying-glass"
                @click="search"
                :disabled="!validSearch"
              />
            </v-col>
          </v-row>
        </v-form>

        <v-row dense>
          <v-col cols="12">
            <v-tabs v-model="tab" right class="b-tab-horizontal">
              <template v-for="(header, index) in tabs">
                <v-tab :href="`#tab-${index}`" :key="index">
                  <v-icon left :size="20">
                    {{ getProductTypeIcon(header.productType) }}
                  </v-icon>
                  {{ getProductTypeName(header.productType) }}
                </v-tab>
              </template>

              <v-tabs-items v-model="tab">
                <template v-for="(model, index) in tabs">
                  <v-tab-item
                    :value="`tab-${index}`"
                    :key="index"
                    v-if="`tab-${index}` === tab"
                  >
                    <v-form
                      class="mt-5"
                      :ref="`estimate-${index}`"
                      lazy-validation
                    >
                      <v-row dense>
                        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                          <BaseDatePicker
                            label="Data de início"
                            disabled
                            v-model="model.startDate"
                          />
                        </v-col>

                        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                          <BaseDatePicker
                            label="Data fim de vigência"
                            :disabled="model.readonly"
                            :rules="model.readonly ? [] : [required]"
                            v-model="model.effectiveEndDate"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          xl="2"
                          lg="2"
                          md="2"
                          sm="12"
                          v-if="!model.readonly"
                        >
                          <BaseButton
                            color="secondary"
                            title="Calcular"
                            height="40"
                            @click="estimate(model, `estimate-${index}`)"
                          />
                        </v-col>
                      </v-row>
                    </v-form>

                    <v-row dense>
                      <v-data-table
                        class="b-table-exclusion"
                        dense
                        disable-sort
                        hide-default-footer
                        :headers="headers"
                        :items="model.lives"
                      >
                        <template v-slot:[`item.document`]="{ item }">
                          {{ formatCpf(item.document) }}
                        </template>
                        <template v-slot:[`item.planValue`]="{ item }">
                          {{ formatMoney(item.planValue) }}
                        </template>
                        <template v-slot:[`item.proRataValue`]="{ item }">
                          {{ formatMoney(item.proRataValue) }}
                        </template>
                      </v-data-table>
                    </v-row>

                    <v-form :ref="`execute-${index}`" lazy-validation>
                      <v-row dense class="mt-4">
                        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                          <BaseDatePicker
                            label="Data de envio"
                            :disabled="model.readonly"
                            :rules="model.readonly ? [] : [required]"
                            v-model="model.sendDate"
                          />
                        </v-col>
                        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                          <BaseDatePicker
                            label="Data de demissão"
                            :disabled="model.readonly"
                            :rules="model.readonly ? [] : [required]"
                            v-model="model.resignationDate"
                          />
                        </v-col>
                        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                          <v-autocomplete
                            label="Motivo"
                            dense
                            outlined
                            :disabled="model.readonly"
                            :rules="model.readonly ? [] : [required]"
                            :items="reasons"
                            v-model="model.reason"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense v-if="!model.readonly">
                        <v-col cols="6">
                          <MovementsDocuments
                            :employeeId="employeeId"
                            :dependentId="dependentId"
                            :contractId="model.contractId"
                          />
                        </v-col>
                        <v-col cols="6" class="text-right">
                          <BaseButton
                            outlined
                            color="error darken-1"
                            title="Excluir"
                            @click="execute(model, `execute-${index}`)"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                </template>
              </v-tabs-items>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { getProductTypeName, getProductTypeIcon } from '@/helpers/utils';
import { formatCpf, formatMoney } from '@/helpers/formatting';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  components: {
    MovementsDocuments: () => import('../details/movements-documents')
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    employeeId: {
      type: String,
      required: true
    },
    employeeName: {
      type: String,
      required: true
    },
    dependentId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      require: true,
      default: ''
    }
  },

  data: () => ({
    validSearch: true,
    tab: null,
    tabs: [],
    contracts: [],
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'CPF', value: 'document' },
      { text: 'Parentesco', value: 'kinship' },
      { text: 'Valor do plano', value: 'planValue' },
      { text: 'Valor pro rata', value: 'proRataValue' },
      { text: 'Dias de pro rata', value: 'proRataDays' }
    ],
    reasons: [
      'Falecimento',
      'Pedido de Cancelamento',
      'Perda de Elegibilidade',
      'Maioridade'
    ]
  }),

  methods: {
    formatCpf,
    formatMoney,
    getProductTypeName,
    getProductTypeIcon,

    closeModal() {
      this.$emit('closeModal');
    },

    search() {
      if (!this.$refs.search.validate(true)) {
        return;
      }

      this.handleGet({
        employeeId: this.employeeId,
        dependentId: this.dependentId,
        contracts: this.contracts.join()
      });
    },

    estimate(model, form) {
      if (!this.$refs[form][0].validate(true)) {
        return;
      }

      this.handleGet({
        employeeId: this.employeeId,
        dependentId: this.dependentId,
        contracts: this.contracts.join(),
        productType: model.productType,
        effectiveEndDate: model.effectiveEndDate.split('T')[0]
      });
    },

    async handleGet(params) {
      try {
        const movementService = new MovementService();
        const { status, data } =
          await movementService.getMovementExclusionDependent(params);

        if (status === 200) {
          if ('productType' in params) {
            const payload = {
              ...{ results: data },
              ...params
            };

            const result = payload.results.find(
              x => x.productType === payload.productType
            );

            this.tabs.forEach(item => {
              if (item.productType === payload.productType) {
                item.lives = result.lives;
              }
            });
          } else {
            this.tabs = data;
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async execute(model, form) {
      if (!this.$refs[form][0].validate(true)) {
        return;
      }

      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (result) {
        try {
          const movementService = new MovementService();
          const { status } =
            await movementService.deleteMovementExclusionDependent({
              employeeId: this.employeeId,
              dependentId: this.dependentId,
              productType: model.productType,
              startDate: model.startDate,
              sendDate: model.sendDate,
              resignationDate: model.resignationDate,
              effectiveEndDate: model.effectiveEndDate,
              reason: model.reason
            });

          if (status === 200 || status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    }
  }
};
</script>
